import enquire from 'enquire.js';

const mobileNav = () => {
  $(() => {
    const mobileNavLink = $('#mobile-nav-link');
    const mobileNavDropdown = $('#mobile-nav-dropdown');

    mobileNavLink.on('click', () => {
      const active = mobileNavLink.toggleClass('is-active').hasClass('is-active');
      $('body').css('overflow', !active ? '' : 'hidden');
      mobileNavDropdown.toggleClass('is-active');
    });

    $('.js-mobile-nav-tab-toggle').on('click', (event) => {
      event.preventDefault();
      const item = $(event.currentTarget);
      const tabId = item.data('tab');
      item.toggleClass('is-active');
      $(`#${tabId}`).toggleClass('is-active');
    });

    enquire.register('(max-width: 767px)', {
      unmatch() {
        if (mobileNavLink.hasClass('is-active')) {
          $('body').css('overflow', '');
          mobileNavLink.toggleClass('is-active');
          mobileNavDropdown.toggleClass('is-active');
        }
      },
    });
  });
};

export default mobileNav;
