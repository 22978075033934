import enquire from 'enquire.js';
import { toggleActiveTab } from '../utils';

const primaryNav = () => {
  $(() => {
    const desktopMenuSelect = $('#primary-nav-dropdown');

    $('#primary-nav-link').on('mouseenter touchmove', () => {
      desktopMenuSelect.addClass('is-active');
    });

    desktopMenuSelect.mouseleave(() => {
      desktopMenuSelect.removeClass('is-active');
    });

    $(document).mouseup((event) => {
      if (desktopMenuSelect.hasClass('is-active')) {
        if (
          !$('#primary-nav-dropdown, #primary-nav-link, #primary-nav-link > svg').is(
            event.target,
          ) &&
          desktopMenuSelect.has(event.target).length === 0
        )
          desktopMenuSelect.removeClass('is-active');
      }
    });

    toggleActiveTab(
      'mouseenter touchmove',
      '.js-primary-nav-tab-toggle',
      '.js-primary-nav-tab-wrapper, .js-primary-nav-content-toggle',
      true,
    );

    toggleActiveTab(
      'click',
      '.js-primary-nav-categories-tab-toggle',
      '.js-primary-nav-categories-items-toggle',
    );

    toggleActiveTab(
      'click',
      '.js-primary-nav-instructors-tab-toggle',
      '.js-primary-nav-instructors-items-toggle',
    );

    enquire.register('(max-width: 767px)', {
      match() {
        if (desktopMenuSelect.hasClass('is-active')) {
          desktopMenuSelect.toggleClass('is-active');
        }
      },
    });
  });
};

export default primaryNav;
