export const toggleActiveTab = (onEvents, tabElement, tabDropdownElement, parent = false) => {
  $(tabElement).on(onEvents, (event) => {
    if (onEvents === 'click') {
      event.preventDefault();
    }
    const item = $(event.currentTarget);
    const tabId = item.data('tab');
    $(`${tabElement}, ${tabDropdownElement}`).removeClass('is-active');
    item.addClass('is-active');
    if (parent) {
      item.parent().addClass('is-active');
    }
    return $(`#${tabId}`).addClass('is-active');
  });
};
