const accountDropdown = () => {
  $(() => {
    const dropdownAccount = $('#dropdown-account-link');

    dropdownAccount.click((event) => {
      event.preventDefault();
      const tabId = dropdownAccount.data('dropdown');
      dropdownAccount.attr('aria-expanded', (i, attr) => {
        return attr === 'true' ? 'false' : 'true';
      });
      $(`#${tabId}`).toggle();
    });
  });
};

export default accountDropdown;
