import toastr from 'toastr';

const playlistModal = () => {
  $(() => {
    $('.js-playlist-modal-submit').on('click', (event) => {
      event.preventDefault();
      const { chapterId } = event.currentTarget.dataset;
      const { chapterIndex } = event.currentTarget.dataset;
      const modalId = $(`#playlist-modal-${chapterId}-${chapterIndex}`);
      const playlistCheckboxes = `js-playlist-checkboxes-${chapterId}-${chapterIndex}`;
      const playlistIds = $(`.${playlistCheckboxes}:checkbox:checked`)
        .map(function () {
          return $(this).val();
        })
        .get();
      if (playlistIds.length > 0) {
        const authToken = $('meta[name=csrf-token]').attr('content');
        const chapters = {
          chapter: {
            playlist_ids: playlistIds,
          },
          authenticity_token: authToken,
        };
        $.ajax({
          url: `/chapters/${chapterId}`,
          type: 'PUT',
          data: JSON.stringify(chapters),
          dataType: 'JSON',
          contentType: 'application/json',
          success() {
            toastr.success('Chapter added successfully', '', {
              closeButton: true,
              progressBar: true,
            });
          },
          error() {
            toastr.error('Oops! Something went wrong. Please try again later.', '', {
              closeButton: true,
              progressBar: true,
            });
          },
        });
        modalId.modal('hide');
      } else {
        toastr.error('Select at least one playlist to add a chapter', '', {
          closeButton: true,
          progressBar: true,
        });
      }
    });

    $('.js-modal-create-playlist').on('click', (event) => {
      event.preventDefault();
      const modalId = `#${event.currentTarget.dataset.modalId}`;
      $(modalId).modal('hide');
      window.location.href = event.target.href;
    });
  });
};

export default playlistModal;
