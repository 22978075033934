// Warning: be careful adding any additional JS here as it may risk regressions on new or legacy pages
import { accountDropdown, mobileNav, primaryNav } from '../custom/global';
import '../stylesheets/global.scss';

// Global vendor JS
/* eslint-disable import/no-extraneous-dependencies */
import 'bootstrap/js/src/util';
import 'bootstrap/js/src/modal';

// Custom JS
import playlistModal from '../custom/global/playlistModal';
/* eslint-enable import/no-extraneous-dependencies */

global.toastr = require('toastr');

accountDropdown();
mobileNav();
primaryNav();
playlistModal();
